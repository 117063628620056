import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";

import ProductFilters from "../components/product-filters";
import ProductsList from "../components/products-list";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query ProductCategoryQuery($id: String!) {
    currentCat: sanityProductCategory(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      description
      featuredImage {
        ...SanityImage
        alt
      }
    }

    products: allSanityProduct(
      sort: { fields: [title], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          categories {
            productCategory {
              id
            }
          }
          featuredImage {
            ...SanityImage
            alt
          }
          secondaryFeaturedImage {
            ...SanityImage
            alt
          }
        }
      }
    }

    productCats: allSanityProductCategory(
      sort: { fields: [orderRank], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
        }
      }
    }

    productTags: allSanityProductTag(
      sort: { fields: [orderRank], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
        }
      }
    }
  }
`;

const ProductCategoryTemplate = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const currentCat = (data || {}).currentCat;
  const products = (data || {}).products.edges.filter(post => (post.node.categories.map(({productCategory}) => productCategory.id).includes(currentCat.id)));
  const productCats = (data || {}).productCats;
  const productTags = (data || {}).productTags;

  return (
    <Layout>
      <Seo
        title={currentCat.title}
        description={currentCat.description}
        image={currentCat.featuredImage}
      />

      {(productCats || productTags) && (<ProductFilters categories={productCats} tags={productTags} currentCategory={currentCat} />)}
      {products && (<ProductsList products={products} />)}
    </Layout>
  );
};

export default ProductCategoryTemplate;
